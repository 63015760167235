import React from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import Layout from "../components/layout"

export default class EmailSignup extends React.Component {

    state = {
        name: null,
        email: null,
    }

    _handleChange = e => {
      console.log({
            [`${e.target.name}`]: e.target.value,
      })
      this.setState({
            [`${e.target.name}`]: e.target.value,
      })
    }

    _handleSubmit = e => {
      e.preventDefault()

      console.log('submit', this.state)

      addToMailchimp(this.state.email, this.state)
      .then(({ msg, result }) => {
        console.log('msg', `${result}: ${msg}`)

        if (result !== 'success') {
          throw msg
        }
        alert(msg)
      })
      .catch(err => {
        console.log('err', err)
        alert(err)
      })
    }

    render() {
      return (
        <Layout>
          <div>
            <h1>Get in the Groove, get on the mailing list.</h1>
            <p>We'll pass along our weekly menu of livestreams, mixes, and all Café news.</p>
            <div>
              <form onSubmit={this._handleSubmit}>
                <input
                  type="email"
                  onChange={this._handleChange}
                  placeholder="email"
                  name="email"
                />
                <br />
                <br />

                <input type="submit" />
              </form>
            </div>
          </div>
        </Layout>
          )
          }

}
